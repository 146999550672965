export const watchVisibility = () => {
  let eventName, previous;
  if (typeof document.hidden !== "undefined") {
    eventName = "visibilitychange";
  } else if (typeof document.msHidden !== "undefined") {
    eventName = "msvisibilitychange";
  } else if (typeof document.webkitHidden !== "undefined") {
    eventName = "webkitvisibilitychange";
  }

  if (eventName) {
    previous = document.visibilityState;
    document.addEventListener(eventName, () => {
      const current = document.visibilityState;
      let leaveTime, currentTime;
      if (previous === "visible" && current === "hidden")
        localStorage.setItem("l_t", new Date());
      else if (previous === "hidden" && current === "visible") {
        leaveTime = new Date(localStorage.getItem("l_t"));
        currentTime = new Date();
        // If user was gone for more than 5 min, then reload the window
        if ((currentTime - leaveTime) / (1000 * 60) > 5)
          window.location.reload();
      }
      previous = current;
    });
  }
};
