export const isMobile = props => {
  if (process.browser) {
    if (document.getElementById("device").dataset["device"] === "mobile")
      return true;
  } else {
    if (props && props.device === "mobile") return true;
  }
  return false;
};

export const isGlanceSupported = props => {
  if (typeof document === "undefined" || !document.getElementById("device")) {
    return;
  }

  if (process.browser) {
    return (
      document.getElementById("device").dataset["isglancesupported"] == "true"
    );
  } else {
    return props ? props.isGlanceSupported : false;
  }
};

export const getEnableGlanceUrl = props => {
  if (process.browser) {
    return document.getElementById("device").dataset["enableglanceurl"];
  } else {
    return props ? props.enableGlanceUrl : "";
  }
};

export const getBrowser = props => {
  if (process.browser) {
    return document.getElementById("device").dataset["browser"];
  } else {
    return props ? props.enableGlanceUrl : "";
  }
};

export const getReferer = props => {
  if (process.browser) {
    return document.getElementById("device").dataset["referer"];
  } else {
    return props ? props.enableGlanceUrl : "";
  }
};

export const getUserCountryCode = req => {
  if (process.browser) {
    return document.getElementById("device").dataset["usercountrycode"];
  } else {
    return req ? req.userCountryCode : null;
  }
};
