export default [
  {
    isFixed: true,
    highlights: [
      {
        id: "fixed-glance-id-1",
        imageId: "fixed-glance-image-id-1",
        image: {
          id: "fixed-glance-image-id-1",
          supportedImages: [],
          url:
            "https://web.glance-cdn.com/egc_pwa/ui/images/buzzstorm/glance/generic-slide-1.jpg"
        },
        overlayImage: {
          id: "f3baf3efdbe33b28d3a2b7cfac3f682f9d5a1131",
          supportedImages: [],
          url:
            "https://web.glance-cdn.com/egc_pwa/ui/images/buzzstorm/glance/overlay-slide-1.svg"
        },
        type: "STORY",
        createdAtInSecs: 1611035602,
        updatedAtInSecs: 1611035605,
        startsAtInSecs: 1611035570,
        endsAtInSecs: 1611121970,
        priority: 0,
        homescreenWorthy: true,
        shareUrl: "https://experience.glance.com/",
        peekData: {
          title: "",
          sourceName: "",
          heartCount: 0,
          shortUrl:
            "https://www.femina.in/life/travel/6-things-to-do-in-alappuzha-kerala-167515-1.html",
          ctaText: "Read More"
        },
        peek: {
          type: "ARTICLE",
          articlePeek: {
            summary: "",
            cta: {
              url:
                "https://www.femina.in/life/travel/6-things-to-do-in-alappuzha-kerala-167515-1.html",
              text: "Read More",
              shouldUnlock: false,
              ctaType: "OPEN_URL",
              openUrlCta: {
                url:
                  "https://www.femina.in/life/travel/6-things-to-do-in-alappuzha-kerala-167515-1.html",
                shouldUnlock: false,
                ctaDisplay: {
                  displayType: "TEXT",
                  ctaDisplayText: { text: "Read More" }
                }
              }
            },
            canSkipSummary: true,
            loadAndroidJs: false
          }
        },
        attribution: { text: "© Getty Images (Representation)" },
        categories: [
          { id: "#daily_digest", displayName: "Daily Digest" },
          { id: "#international", displayName: "World News" }
        ],
        source: "Glance",
        frequencyCaps: {},
        renderSessionMode: "ANY",
        renderProperty: 11,
        languageId: "en",
        glanceInteractionDetails: { likeCount: 667, shareCount: 303 },
        bubbleDetails: {
          id: "85b56862b2",
          name: "#FunFacts",
          imageUrl:
            "https://web.glance-cdn.com/egc_pwa/ui/images/buzzstorm/slide-2-author-min.png",
          overlayImageUrl:
            "https://web.glance-cdn.com/egc_pwa/ui/images/buzzstorm/glance/overlay-slide-1.png",
          autoNext: false
        },
        featureBankWorthy: false,
        isShareable: true,
        userNetworkType: 7
      },
      {
        id: "fixed-glance-id-2",
        imageId: "fixed-glance-image-id-2",
        image: {
          id: "fixed-glance-image-id-2",
          supportedImages: [],
          url:
            "https://web.glance-cdn.com/egc_pwa/ui/images/buzzstorm/glance/generic-slide-2.jpg"
        },
        overlayImage: {
          id: "7d5b1c7ca44919caaa7da5c0a00ceb10ef2f84bb",
          supportedImages: [],
          url:
            "https://web.glance-cdn.com/egc_pwa/ui/images/buzzstorm/glance/overlay-slide-2.png"
        },
        type: "STORY",
        createdAtInSecs: 1611035488,
        updatedAtInSecs: 1611037963,
        startsAtInSecs: 1611035450,
        endsAtInSecs: 1611121850,
        priority: 0,
        homescreenWorthy: true,
        shareUrl: "https://experience.glance.com/",
        peekData: {
          title: "",
          sourceName: "",
          heartCount: 0,
          shortUrl:
            "https://www.treehugger.com/weird-facts-about-camels-4864533",
          ctaText: "Read More"
        },
        peek: {
          type: "ARTICLE",
          articlePeek: {
            summary: "",
            cta: {
              url:
                "https://www.treehugger.com/weird-facts-about-camels-4864533",
              text: "Read More",
              shouldUnlock: false,
              ctaType: "OPEN_URL",
              openUrlCta: {
                url:
                  "https://www.treehugger.com/weird-facts-about-camels-4864533",
                shouldUnlock: false,
                ctaDisplay: {
                  displayType: "TEXT",
                  ctaDisplayText: { text: "Read More" }
                }
              }
            },
            canSkipSummary: true,
            loadAndroidJs: false
          }
        },
        attribution: { text: "© Getty Images (Representation)" },
        categories: [
          { id: "#daily_digest", displayName: "Daily Digest" },
          { id: "#international", displayName: "World News" }
        ],
        source: "Glance",
        frequencyCaps: {},
        renderSessionMode: "ANY",
        renderProperty: 11,
        languageId: "en",
        glanceInteractionDetails: { likeCount: 1014, shareCount: 325 },
        bubbleDetails: {
          id: "85b56862b2",
          name: "#FunFacts",
          imageUrl:
            "https://web.glance-cdn.com/egc_pwa/ui/images/buzzstorm/slide-2-author-min.png",
          overlayImageUrl:
            "https://web.glance-cdn.com/egc_pwa/ui/images/buzzstorm/glance/overlay-slide-2.svg",
          autoNext: false
        },
        featureBankWorthy: false,
        isShareable: true,
        userNetworkType: 7
      }
    ]
  },
  [
    {
      autoNext: false,
      bubbleScore: 0.0,
      bubbleStartTime: 202107291831,
      featureBankGlances: [],
      glanceContext: [null, null, null],
      glances: [
        {
          attribution: { text: "© Getty Images (Representation)" },
          bubbleId: "85b56862b2",
          bubbleTitle: "#FunFacts",
          canShowKeyBoard: false,
          duration: 7200000,
          glanceId: "fixed-glance-id-1",
          isDynamicOverlay: false,
          isFallback: false,
          isFeatureBank: false,
          isHomeScreenWorthy: true,
          isOfflinePeekAvailable: false,
          isShareable: true,
          isSponsored: false,
          lastRenderedAtHighlights: 1627577349703,
          lastRenderedAtLockScreen: -1,
          likeCount: 667,
          liveViewCount: 0,
          localImageUri:
            "https://web.glance-cdn.com/egc_pwa/ui/images/buzzstorm/glance/generic-slide-1.jpg",
          localOverlayUri:
            "https://web.glance-cdn.com/egc_pwa/ui/images/buzzstorm/glance/overlay-slide-1.svg",
          peek: {
            type: 1,
            articlePeek: {
              summary: "",
              cta: {
                url:
                  "https://www.femina.in/life/travel/6-things-to-do-in-alappuzha-kerala-167515-1.html",
                text: "Read More",
                shouldUnlock: false,
                ctaType: "OPEN_URL",
                openUrlCta: {
                  url:
                    "https://www.femina.in/life/travel/6-things-to-do-in-alappuzha-kerala-167515-1.html",
                  shouldUnlock: false,
                  ctaDisplay: {
                    displayType: "TEXT",
                    ctaDisplayText: { text: "Read More" }
                  }
                }
              },
              canSkipSummary: true,
              loadAndroidJs: false
            }
          },
          peekData: {
            title: "",
            sourceName: "",
            heartCount: 0,
            shortUrl:
              "https://www.femina.in/life/travel/6-things-to-do-in-alappuzha-kerala-167515-1.html",
            ctaText: "Read More"
          },
          shareCount: 303,
          shareUrl: "https://experience.glance.com/",
          source: "Glance",
          startTime: 1627577100000,
          thumbnailUrl:
            "https://web.glance-cdn.com/egc_pwa/ui/images/buzzstorm/slide-2-author-min.png",
          type: 1
        },
        {
          attribution: { text: "© Getty Images (Representation)" },
          bubbleId: "85b56862b2",
          bubbleTitle: "#FunFacts",
          canShowKeyBoard: false,
          duration: 7000,
          glanceId: "fixed-glance-id-2",
          isDynamicOverlay: false,
          isFallback: false,
          isFeatureBank: false,
          isHomeScreenWorthy: true,
          isOfflinePeekAvailable: false,
          isShareable: true,
          isSponsored: false,
          lastRenderedAtHighlights: -1,
          lastRenderedAtLockScreen: -1,
          likeCount: 1414,
          liveViewCount: 0,
          localImageUri:
            "https://web.glance-cdn.com/egc_pwa/ui/images/buzzstorm/glance/generic-slide-2.jpg",
          localOverlayUri:
            "https://web.glance-cdn.com/egc_pwa/ui/images/buzzstorm/glance/overlay-slide-2.png",
          peek: {
            type: 1,
            articlePeek: {
              summary: "",
              cta: {
                url:
                  "https://www.treehugger.com/weird-facts-about-camels-4864533",
                text: "Read More",
                shouldUnlock: false,
                ctaType: "OPEN_URL",
                openUrlCta: {
                  url:
                    "https://www.treehugger.com/weird-facts-about-camels-4864533",
                  shouldUnlock: false,
                  ctaDisplay: {
                    displayType: "TEXT",
                    ctaDisplayText: { text: "Read More" }
                  }
                }
              },
              canSkipSummary: true,
              loadAndroidJs: false
            }
          },
          peekData: {
            title: "",
            sourceName: "",
            heartCount: 0,
            shortUrl:
              "https://www.treehugger.com/weird-facts-about-camels-4864533",
            ctaText: "Read More"
          },
          shareCount: 325,
          shareUrl: "https://experience.glance.com/",
          source: "GLANCE",
          startTime: 1627535220000,
          thumbnailUrl:
            "https://web.glance-cdn.com/egc_pwa/ui/images/buzzstorm/slide-2-author-min.png",
          type: 1
        }
      ],
      hasUnseenGlances: true,
      id: "85b56862b2",
      imageUrl:
        "https://web.glance-cdn.com/egc_pwa/ui/images/buzzstorm/slide-2-author-min.png",
      isFallback: false,
      isPeekBubble: false,
      isSponsored: false,
      name: "#FunFacts",
      overlayImage:
        "https://web.glance-cdn.com/egc_pwa/ui/images/buzzstorm/glance/overlay-slide-2.png",
      priority: 0,
      receivedAt: 1627572023002,
      seenGlancesInfo: [1, 1, 0],
      startPosition: 0
    }
  ]
];
