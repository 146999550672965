export const hasGoldenGateBridge = () =>
  typeof window.GoldenGateBridge !== "undefined";

export const launchDetailWebViewScreen = url => {
  try {
    window.GoldenGateBridge.launchDetailWebViewScreen(url);
  } catch (err) {
    console.error(err);
  }
};

/***
 *  data is an object of the format
 * {
 *  "title":" #hello gggg", // Title
 *  “packageName”:”com.whatsapp” // If need to be sent to specific application or should be null
 *  “imageUrl”:” https://uat.koralapp.com/poll/hello-gggg/scr33nsh0t” //Url for the image if available,
 *  “url”:” https://uat.koralapp.com/poll/hello-gggg/scr33nsh0t” // URL for the shared content,
 *  “message”:”Lets share it”// message to be shown while sharing
 * }
 *
 */
export const shareContent = data => {
  try {
    window.GoldenGateBridge.shareContent(JSON.stringify(data));
  } catch (err) {
    console.error(err);
  }
};

export const openExternalBrowser = url => {
  try {
    window.GoldenGateBridge.launchExternalBrowser(url);
  } catch (err) {
    console.error(err);
  }
};

export const isInstantApp = () => {
  try {
    return window.GoldenGateBridge.isInstantApp();
  } catch (err) {
    console.error(err);
  }
  return false;
};
