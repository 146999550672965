import { fetchDemoInBatch, fetchInBatch } from "../utils/glance";
import { useEffect, useState } from "react";

import fixedGlances from "../components/Splash/fixedGlances";
import { isGlanceSupported } from "../utils/device";
import { merge } from "./array";
import getConfig from "next/config";
import {
  hasGoldenGateBridge,
  launchDetailWebViewScreen,
  shareContent
} from "../utils/goldengatebridge";
import {
  appInstallService,
  checkBridgeInterface,
  checkOciInterface,
  getGlanceSdkVersion,
  isAppInstalled,
  isKeyguardLocked,
  launchApp,
  launchAppAfterUnlock,
  launchAppFallBack,
  launchIntentAfterUnlock,
  singleAppInstallService
} from "./glanceJsBridges";
import { getSessionStorage, storeSessionStorage } from "./browser";
import {
  JIO_APP_DATA,
  JIO_CINEMAS,
  JIO_CINEMAS_WEB,
  SDK_VERSION_816,
  XIAOMI_PACKAGE
} from "../common/constants";
import { fireEvent, sendAnalytics } from "./tracking";
const { publicRuntimeConfig } = getConfig();
const showConfirmation = null;
const installLaterOnUnlockCancel = null;
const CDN_URL = "https://g.glance-cdn.com/public/content/assets/xiaomi";

const {
  appName,
  packageName,
  appBeacons,
  ctaBeaconUrl,
  appLogo,
  brandColor,
  appSize,
  downloads,
  appSizeUnit,
  stars,
  nudgeConfig,
  installLater
} = JIO_APP_DATA.data;

const app = [
  {
    appName,
    packageName,
    appBeacons,
    ctaBeaconUrl,
    installLater,
    showConfirmation,
    installLaterOnUnlockCancel,
    appLogo,
    detailedInfo: {
      logoUrl: `${CDN_URL}/${appLogo}`,
      nudgeCtaText: nudgeConfig.nudgeCtaText,
      nudgeDesc: nudgeConfig.nudgeDesc,
      size: `${appSize} ${appSizeUnit}`,
      rating: stars,
      downloadCount: downloads
    },
    brandColor: brandColor?.lightTone,
    sbc: brandColor?.darkTone,
    ext: ""
  }
];

export function useGlances(props) {
  const [state, setState] = useState({ glances: props.glances });
  const { onb, glances, ids } = props;
  const isGlanceSupportedDevice = isGlanceSupported(props);
  useEffect(() => {
    if (onb === "1") {
      localStorage.setItem("coachmark_s", 1);
    }
    const idsTofetch = ids.reduce((result, item) => {
      if (
        !glances.find(function(glance) {
          return item === glance?.id;
        })
      ) {
        result.push(item);
      }
      return result;
    }, []);

    fetchInBatch({
      ids: idsTofetch
    }).then(function(result) {
      const staticCardPosition = [];

      const enableGlanceCardPosition = [
        { pos: 2, color: "black" },
        { pos: 9, color: "white" },
        { pos: 14, color: "red" },
        { pos: 19, color: "black" },
        { pos: 29, color: "white" }
      ];

      const updatedGlanceList = isGlanceSupportedDevice
        ? addEnableGlanceCards(merge(glances, result), enableGlanceCardPosition)
        : addStaticCards(merge(glances, result), staticCardPosition);
      setState({ glances: updatedGlanceList });
    });
  }, [onb, glances, ids, isGlanceSupportedDevice]);
  return state;
}

let checkHasCoachMarkOnce = false,
  checkHasCoachMark = false;
export function getGlancesWithBubbles(
  glances,
  userOnboarded,
  includeFixedGlances = false,
  storyPick = false
) {
  const bubbleObj = {},
    newGlances = [];
  if (glances && glances.length > 0) {
    glances.forEach((glance, index) => {
      if (
        glance &&
        glance.overlayImage &&
        glance.overlayImage.url &&
        glance.image.url
      ) {
        const { bubbleDetails = null } = glance;
        if ((storyPick && index === 0) || bubbleDetails) {
          let id;
          if (index === 0 && !bubbleDetails) {
            id = 0;
          } else {
            id = bubbleDetails.id;
          }

          if (!bubbleObj[id]) {
            bubbleObj[id] = { index, highlights: [] };
            newGlances.push(bubbleObj[id]);
          }
          bubbleObj[id].highlights.push(glance);
        }
      }
    });
  }

  newGlances.sort((a, b) => a.index - b.index);

  if (includeFixedGlances) {
    if (process.browser && !userOnboarded) {
      if (!checkHasCoachMarkOnce) {
        checkHasCoachMark = localStorage.getItem("coachmark_s");
        checkHasCoachMarkOnce = true;
      }
      if (!checkHasCoachMark) {
        return [...fixedGlances, ...newGlances];
      }
    }
  }

  return newGlances;
}

export function youtubeSupport() {
  const tag = document.createElement("script");
  tag.src = "https://www.youtube.com/iframe_api";
  tag.async = true;
  const firstScriptTag = document.getElementsByTagName("script")[0];
  if (firstScriptTag && firstScriptTag.parentNode) {
    firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);
    window.youtubeScriptLoading = true;
    window.onYouTubeIframeAPIReady = function() {
      window.youtubeScriptLoading = false;
    };
  }
}

/**
 * Adding queryparam source=api-integration check,
 * 1. In default case, if we bypass glance.overlayImage && glance.overlayImage.url, glances are morelike wallpapers.
 * 2. In case of Oppo integration, we are bypassing this check, as these are glances don't have overlay images.
 */

const checkOverlayImage = (isSourceApiIntegration, glance) =>
  isSourceApiIntegration
    ? true
    : Boolean(glance.overlayImage && glance.overlayImage.url);

const ignoreYoutubeVideo = (forXiaomiReactivation, glance) =>
  forXiaomiReactivation &&
  (glance.peek.type === 2 || glance.peek.type === "VIDEO")
    ? true
    : false;
export function getGlancesWithBubblesForRender(
  glances = [],
  storyPick = false,
  isSourceApiIntegration = false,
  forXiaomiReactivation = false
) {
  const bubbleObj = {},
    newGlances = [];
  if (glances.length <= 0 || !Array.isArray(glances)) {
    return;
  }
  glances.forEach((glance, index) => {
    if (
      glance &&
      glance.image &&
      glance.image.url &&
      glance.bubbleDetails &&
      glance.peek &&
      checkOverlayImage(isSourceApiIntegration, glance) &&
      !ignoreYoutubeVideo(forXiaomiReactivation, glance)
    ) {
      const id = glance.bubbleDetails.id;
      if (!bubbleObj[id]) {
        bubbleObj[id] = {
          autoNext: glance.bubbleDetails.autoNext,
          bubbleScore: 0,
          bubbleStartTime: glance.startsAtInSecs,
          featureBankGlances: [],
          glanceContext: [null],
          hasUnseenGlances: false,
          id: glance.bubbleDetails.id,
          imageUrl: glance.bubbleDetails.imageUrl,
          isFallback: false,
          isPeekBubble: false,
          isSponsored: false,
          name: glance.bubbleDetails.name,
          overlayImage: glance?.overlayImage?.url,
          priority: glance.priority,
          receivedAt: 1627572739574,
          glances: [getGlanceFormat(glance)]
        };
        newGlances.push(bubbleObj[id]);
      } else {
        bubbleObj[id].glances.push(getGlanceFormat(glance));
      }
    } else if (glance) {
      if (storyPick && index === 0) {
        const id = 0;
        if (!bubbleObj[id]) {
          bubbleObj[id] = {
            id: glance.id,
            glances: [getGlanceFormat(glance)]
          };
          newGlances.push(bubbleObj[id]);
        } else {
          bubbleObj[id].glances.push(getGlanceFormat(glance));
        }
      }
    }
  });
  return newGlances;
}

export function getFixedGlances() {
  if (process.browser) {
    return [...fixedGlances];
  }
}

function getGlanceFormat(glance) {
  return {
    attribution: { text: glance.attribution?.text },
    bubbleId: glance.bubbleDetails ? glance.bubbleDetails.id : glance.id,
    bubbleTitle: glance.bubbleDetails ? glance.bubbleDetails.name : null,
    canShowKeyBoard: false,
    duration: 7000,
    glanceId: glance.id,
    isDynamicOverlay: glance.dynamicOverlay,
    isFallback: false,
    isFeatureBank: false,
    isHomeScreenWorthy: false,
    isOfflinePeekAvailable: false,
    isShareable: glance.isShareable,
    isSponsored: false,
    lastRenderedAtHighlights: -1,
    lastRenderedAtLockScreen: -1,
    likeCount: glance.glanceInteractionDetails?.likeCount,
    liveViewCount: 0,
    peek: getPeekENUM(glance),
    peekData: glance.peekData,
    shareCount: glance.glanceInteractionDetails?.shareCount,
    shareUrl: glance.shareUrl,
    source: glance.source,
    startTime: glance.startsAtInSecs,
    thumbnailUrl: glance.bubbleDetails ? glance.bubbleDetails.imageUrl : null,
    type: 1,
    localImageUri: glance.image?.url,
    localOverlayUri: glance?.overlayImage?.url,
    isFixed: glance.isFixed ? glance.isFixed : false
  };
}

function getPeekENUM(glance) {
  if (glance.peek) {
    if (glance.peek.type === 2 || glance.peek.type == "VIDEO") {
      return {
        type: 2,
        videoPeek: {
          canSkipSummary: glance.peek.videoPeek?.canSkipSummary,
          loadAndroidJs: glance.peek.videoPeek?.loadAndroidJs,
          summary: glance.peek.videoPeek?.summary,
          video: {
            customizationType: 0,
            type: 2,
            youtube: {
              ration: {
                height:
                  glance.peek.videoPeek?.video?.youtubeVideo?.aspectRatio
                    ?.height,
                width:
                  glance.peek.videoPeek?.video?.youtubeVideo?.aspectRatio?.width
              },
              id: glance.peek.videoPeek?.video?.youtubeVideo?.videoId
            }
          }
        }
      };
    } else if (Number.isInteger(glance.peek.type)) {
      return glance.peek;
    }

    switch (glance.peek.type) {
      case "ARTICLE":
        glance.peek.type = 1;
        return glance.peek;
      case "NATIVE_VIDEO":
        glance.peek.type = 4;
        return glance.peek;
      case "WEB":
        glance.peek.type = 1;
        return glance.peek;
      case "GOOGLE_NATIVE_AD":
        glance.peek.type = 5;
        return glance.peek;
      case "LIVE_VIDEO":
        glance.peek.type = 6;
        return glance.peek;
      case "IMA_AD":
        glance.peek.type = 7;
        return glance.peek;
    }
  }
}

export function onShareClick(glanceString) {
  const glance = glanceString;
  const isFixed = glance.isFixed;
  const title = glance.peekData && glance.peekData.title;

  const url = isFixed
    ? glance.shareUrl
    : `https://${publicRuntimeConfig.domain}/story/${glance.glanceId}`;

  const imageUrl = `${glance.localImageUri}`;

  if (hasGoldenGateBridge()) {
    shareContent({
      title,
      packageName: "com.whatsapp",
      url,
      imageUrl
    });
  } else {
    const link = document.createElement("a");

    const shareText = `${title}`;
    link.href = `whatsapp://send?text=${encodeURIComponent(
      `${shareText}\n${url}`
    )}`;
    link.click();

    setTimeout(() => {
      if (document.hasFocus()) {
        if (navigator && navigator.share) {
          navigator
            .share({
              title,
              text: "",
              url
            })
            .catch(e => {
              //using whatsapp api to share if there is an exception on share tray.
              sendViaWhatsAppApi(url);
            });
        } else {
          sendViaWhatsAppApi(url);
        }
      }
    }, 1250);
  }
}

function sendViaWhatsAppApi(url) {
  const link = document.createElement("a");
  link.target = "_blank";
  link.href = `https://api.whatsapp.com/send?text=${url}`;
  link.click();
}

export function onLikeClicked(glance) {
  if (!glance) return;

  const key_liked = "liked_glaces";
  const likedGlances = JSON.parse(localStorage.getItem(key_liked)) || [];
  let newList = likedGlances;
  const id = glance.glanceId;
  if (likedGlances.includes(id)) {
    newList = newList.filter(item => item !== glance.glanceId);
  } else {
    newList.push(glance.glanceId);
  }
  newList = [...new Set(newList)];
  localStorage.setItem(key_liked, JSON.stringify(newList));
  return newList.includes(id);
}

export function onCTAClicked(glance, glanceIndex) {
  try {
    if (hasGoldenGateBridge()) {
      launchDetailWebViewScreen(glance.peekData.shortUrl);
    } else {
      const ctaBufferValue = parseInt(
        getSessionStorage("ctaActivationBuffer") || 0
      );
      storeSessionStorage("ctaActivationBuffer", glanceIndex + ctaBufferValue);
      const link = document.createElement("a");
      link.target = "_blank";
      link.rel = "noopener noreferrer";
      link.href = glance.peekData.shortUrl;
      link.click();
    }
  } catch (err) {
    console.error(err);
  }
}

export const isXiaomiOem = () => {
  return XIAOMI_PACKAGE.some(packageName => {
    return isAppInstalled(packageName);
  });
};

const installService = () => {
  try {
    if (checkBridgeInterface) {
      if (parseInt(getGlanceSdkVersion()) >= SDK_VERSION_816) {
        const [data] = app;
        const configData = {
          installLater: true,
          autoAppOpen: true,
          autoAppOpenDelay: 3,
          appInstallStartDelay: 3,
          aonc: {
            sn: false,
            wu: nudgeConfig.webURL,
            hwe: nudgeConfig.hardwareExhilaration,
            nct: nudgeConfig.nudgeCtaText,
            nd: nudgeConfig.nudgeDesc,
            nm: Number(nudgeConfig.nudgeMode)
          }
        };

        launchIntentAfterUnlock(null, "");

        singleAppInstallService(
          JSON.stringify(data),
          JSON.stringify(configData)
        );
      } else {
        appInstallService(JSON.stringify(app));
      }
      sendAnalytics("app_install_triggered_now", app, "EGC_OCI");
      sendAnalytics("unlock_success", app, "EGC_OCI");
    }
  } catch (error) {
    console.log(error);
  }
};

export function openJioApp() {
  try {
    const isXiaomi = isXiaomiOem();
    const source = "EGC";

    if (isXiaomi && checkOciInterface) {
      sendAnalytics("EGC_BANNER_CLICKED", "JIO_CINEMAS", "EGC_OCI");

      if (isAppInstalled(JIO_APP_DATA.data.packageName)) {
        if (isKeyguardLocked()) {
          sendAnalytics(
            "app_launch_after_unlock_triggered_now",
            app,
            "EGC_OCI"
          );
          launchAppAfterUnlock(JIO_APP_DATA.data.packageName, "");
        } else {
          sendAnalytics("app_launch_triggered_now", app, "EGC_OCI");

          launchApp(JIO_APP_DATA.data.packageName, "");
        }
      } else {
        installService();
      }
    } else if (checkBridgeInterface) {
      sendAnalytics("EGC_BANNER_CLICKED", "JIO_CINEMAS", source);

      launchAppFallBack(
        JIO_CINEMAS_WEB,
        JIO_CINEMAS,
        JIO_APP_DATA.data.packageName,
        null,
        sendAnalytics
      );
    } else {
      sendAnalytics("app_launch_in_browser_triggered", "JIO_CINEMAS", source);

      const link = document.createElement("a");
      link.target = "_blank";
      link.rel = "noopener noreferrer";
      link.href = JIO_CINEMAS;
      link.click();
    }
  } catch (error) {
    console.log(error);
  }
}

export function getBannerButtonText() {
  if (checkBridgeInterface) {
    if (isAppInstalled("com.jio.media.ondemand")) {
      fireEvent("JioApp-installed", "JioApp-installed", "JioApp");
      return "Open";
    } else {
      fireEvent("JioApp-not-installed", "JioApp-not-installed", "JioApp");
      return "Get App";
    }
  } else {
    return "Get App";
  }
}

export function useDemoGlances(props) {
  const [state, setState] = useState({ glances: props.glances });
  useEffect(
    function() {
      const idsTofetch = props.ids.reduce((result, item) => {
        if (
          !props.glances.find(function(glance) {
            return item === glance.id;
          })
        ) {
          result.push(item);
        }
        return result;
      }, []);

      fetchDemoInBatch({
        ids: idsTofetch
      }).then(function(glances) {
        setState({ glances: glances });
      });
    },
    [props, props.glances, props.ids]
  );
  return state;
}

function addEnableGlanceCards(glances, enableGlanceCardPosition) {
  const enableGlanceCard = {
    red: {
      id: "redEnableStatic",
      type: "enableGlance",
      color: "red"
    },
    black: {
      id: "blackEnableStatic",
      type: "enableGlance",
      color: "black"
    },
    white: {
      id: "whiteEnableStatic",
      type: "enableGlance",
      color: "white"
    }
  };
  enableGlanceCardPosition.map(card => {
    if (card.pos <= glances.length) {
      const currentCard = enableGlanceCard[card.color];
      currentCard.id = currentCard.id + card.pos;
      glances.splice(card.pos, 0, currentCard);
    }
  });
  return glances;
}

function addStaticCards(glances, staticCardPosition) {
  const staticCard = {
    red: {
      id: "redStatic",
      type: "static",
      color: "red"
    },
    black: {
      id: "blackStatic",
      type: "static",
      color: "black"
    },
    white: {
      id: "whiteStatic",
      type: "static",
      color: "white"
    }
  };
  staticCardPosition.map(card => {
    if (card.pos <= glances.length) {
      const currentCard = staticCard[card.color];
      currentCard.id = currentCard.id + card.pos;
      glances.splice(card.pos, 0, currentCard);
    }
  });
  return glances;
}
