import getConfig from "next/config";

const loadAppInsights = new Promise(function(resolve, reject) {
  if (process.browser) {
    const { publicRuntimeConfig } = getConfig();
    if (publicRuntimeConfig.insights) {
      import("@microsoft/applicationinsights-web").then(function(_module) {
        const { ApplicationInsights } = _module;
        const instance = new ApplicationInsights({
          config: {
            instrumentationKey: publicRuntimeConfig.INSTRUMENTATION_KEY,
            enableAutoRouteTracking: true
          }
        });
        instance.loadAppInsights();
        instance.trackPageView();
        instance.addTelemetryInitializer(envelope => {
          if (envelope.baseData.name === "GET /api/v1/version") {
            return false;
          }
          return true;
        });
        resolve(instance);
      });
    }
  }
});

export default loadAppInsights;
